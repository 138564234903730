.contenido_titulo {
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.contenido_grupo .buscar {
  width: 100%;
  height: 30px;
  outline: none;
  border: none;
  padding: 10px 10px 10px 35px;
  font-size: 18px;
}

.contenido_grupo .buscar:hover {
  border-bottom: 2px solid #3283c5;
}

.contenido_grupo .buscar::placeholder {
  color: #2271b3;
  font-weight: 500;
  transition: all 0.5s;
}

.contenido_grupo .buscar:hover::placeholder {
  color: #2271b3;
  font-weight: 500;
  font-size: 13px;
}

/*Tarjetas*/
.target {
  height: 300px;
  box-shadow: 0 0 5px 1px rgba(80, 77, 77, 0.2);
  border-radius: 5px;
  font-size: 16px;
}

.target:hover {
  box-shadow: 0 0 5px 5px rgba(80, 77, 77, 0.2);
}

.tarj {
  width: 100%;
  height: 200px;
  overflow: auto;
  margin-bottom: 20px;
}

.tarj p b {
  font-weight: 600;
}

/*Modal*/
/*inpu*/
.form-group .modal-input {
  color: #333;
  height: 100%;
  width: 100%;
  outline: none;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 400;
  border: none;
  border-bottom: 2px solid #969292;
  box-shadow: 0 0 5px 1px rgba(112, 110, 110, 0.2);
}

.form-group .modal-input:hover {
  box-shadow: 0 0 5px 2px rgba(80, 77, 77, 0.2);
}

/*Tabla para el detalle*/
.contenedor-table {
  max-width: 100%;
  max-height: 200px;
  overflow: auto;
  position: sticky;
  top: 0;
}

#document-detail-table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 5px;
  table-layout: fixed;
}

#document-detail-table caption {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
  margin: 2px 0px;
}

#document-detail-table tr {
  background-color: #f8f8f8;
  border: 1px solid #ddd;
}

#document-detail-table th,
#document-detail-table td {
  font-size: 13px;
  padding: 2px;
  text-align: center;
}

#document-detail-table thead th {
  text-transform: uppercase;
  background-color: #7a7676;
  position: sticky;
  color: #fff;
  top: 0;
  padding: 2px;
}
#document-detail-table tbody tr {
  color: #333;
}
#document-detail-table tbody tr td {
  border: 1px solid #d1cccc;
}
#document-detail-table tbody tr:hover {
  background-color: rgba(121, 116, 116, 0.2);
}

#document-detail-table tbody td:hover {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}

.codigo {
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  #document-detail-table {
    border: 0px;
  }
  #document-detail-table caption {
    font-size: 16px;
  }
  #document-detail-table thead {
    display: none;
  }
  #document-detail-table tr {
    margin-bottom: 8px;
    border-bottom: 4px solid #ddd;
    display: block;
  }
  #document-detail-table th,
  #document-detail-table td {
    font-size: 16px;
  }
  #document-detail-table td {
    display: block;
    border-bottom: 1px solid #ddd;
    text-align: left;
  }
  #document-detail-table td:last-child {
    border-bottom: 0px;
  }
  #document-detail-table td::before {
    content: attr(data-label);
    font-weight: bold;
    text-transform: uppercase;
    float: left;
  }
  .codigo {
    font-weight: 800;
  }
}

.your-custom-swal-class {
  z-index: 9999; /* Establece un valor alto de z-index */
}
