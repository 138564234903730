*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper{
  position: relative;
  max-width: 430px;
  width: 100%;
  background: #fff;
  padding: 34px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.2);
  margin: 10px;
}
.wrapper h2{
  position: relative;
  font-size: 22px;
  font-weight: 600;
  color: #333;
  text-align: center;
}
.wrapper form{
  margin-top: 30px;
}
.wrapper form .input-box{
  height: 52px;
  margin: 18px 0;
}
form .input-box input{
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 15px;
  font-size: 17px;
  font-weight: 400;
  color: #333;
  border: 1.5px solid #C7BEBE;
  transition: all 0.3s ease;
}
.input-box input:focus,
.input-box input:valid{
  border-color: #2271b3;
}

form h3{
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
}
.input-box.button input{
  color: #fff;
  letter-spacing: 1px;
  border: none;
  background: #2271b3;
  cursor: pointer;
  border-radius: 5px;
}
.input-box.button input:hover{
  background: #1b5e96;
}
